<template>
    <div class="index">
        <div class="main">
            <div class="title"></div>
            <div class="text">帐号</div>
            <input class="enter mb36" v-model="username" type="text" placeholder="请输入登录帐号">
            <div class="text">密码</div>
            <input class="enter mb60" v-model="password" type="password"  placeholder="请输入密码">
            <div class="btn" v-loading="loading" @click="btnLogin">登录</div>
        </div>
    </div>
</template>

<script>
import api from '@/libs/api'
import cookie from 'js-cookie'
import { mapMutations } from 'vuex'
import { isEmpty } from '@/libs/util'

export default {
    data() {
        return {
            loading: false,
            username: '',
            password: ''
        }
    },
    mounted () {
        this.setToken('Basic bGF3eWVyLWFwcDpzeXBmMjAyMCM=')
        cookie.remove('lc_token')
    },
    methods: {
        ...mapMutations(['setToken']),
        btnLogin() {
            if (this.loading) return false
            if (!this.username) return this.$message.error('请输入登录帐号')
            if (!this.password) return this.$message.error('请输入密码')

            this.loading = true
            this.$http({
                url: api.token,
                method: 'get',
                data: {
                    username: this.username,
                    password: this.password,
                    grant_type: 'password',
                    jgId: ''
                }
            })
                .then(result => {
                    if (result.status === 200 && !isEmpty(result.data)) {
                        let token = `Bearer ${result.data.access_token}`

                        this.setToken(token)
                        cookie.set('lc_token', token)

                        this.$nextTick(() => {
                            this.$router.replace({
                                name: 'index'
                            })
                        })
                    } else {
                        this.$message.error(result.msg)
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.warn(err)
                    this.$$message.error('登录失败，请重试')
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="less" scoped>
.index {
    background: url(~@/assets/images/bg.jpg) 0 0 no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: auto;
    line-height: 1;
    color: #fff;

    .main {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 580px;
        transform: translate(-50%, -50%);
    }

    .title {
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        background: url(~@/assets/images/login.png) 0 0 no-repeat;
        background-size: 450px 56px;
        width: 450px;
        height: 56px;
        margin: 0 auto;
    }

    .text {
        margin-bottom: 20px;
        font-size: 24px;
    }

    .enter {
        background-color: #fff;
        width: 580px;
        height: 80px;
        padding-left: 32px;
        border-radius: 10px;
        box-sizing: border-box;
        font-size: 24px;
        color: #333;
    }

    .mb36 {
        margin-bottom: 36px;
    }

    .mb60 {
        margin-bottom: 60px;
    }

    .btn {
        background-color: #ddb474;
        width: 580px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
        color: #fff;
    }
}
</style>
